export default defineNuxtPlugin(() => {
  useHead({
    script: [
      {
        src: '//pahtbt.tech/c/maxinewz.hu.js',
        async: true
      }
    ]
  })
})
